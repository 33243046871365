<template>
  <product-container />
</template>

<script>
  import ProductContainer from './product.container';

  export default {
    name: 'ProductView',
    components: {
      ProductContainer,
    },
  }
</script>

<style></style>