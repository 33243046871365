import { amsClient } from '@/service/ams';

const getProduct = (productId) => amsClient.callAms(`/shoprzapi/product/${productId}/?by=rid`);

const makeProductAsLastViewed = (productId) => {
  amsClient.callAms(`/stk/recent_shop/?d=web&pk=${productId}`, {
    method: 'post',
  });
};

export default {
  getProduct,
  makeProductAsLastViewed,
};
