<template>
  <div class="sp-product--content--price">
    <template v-if="selectedVariant">
      <div class="price-value">${{ selectedVariant.price }}</div>

      <div
        v-if="selectedVariant.compareAtPrice"
        class="price-value price-discount"
      >
        ${{ selectedVariant.compareAtPrice }}
      </div>
    </template>

    <template v-else>
      <div class="price-value">
        ${{ product.priceRangeV2.minVariantPrice.amount }}0
      </div>

      <div v-if="maxPrice" class="price-value price-discount">
        ${{ maxPrice }}
      </div>
    </template>

    <div
      v-if="
        productAvailable === 'not_active' || productAvailable === 'out_of_stock'
      "
      class="sp-product--content--status price-status"
      :class="{
        able: productAvailable === 'not_active',
        disable: productAvailable === 'out_of_stock',
      }"
    >
      {{ productAvailable.split('_').join(' ') }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ProductPriceComponent',

    props: {
      product: {
        type: Object,
        default: () => ({}),
      },
      variants: {
        type: Array,
        default: () => [],
      },
      productAvailable: {
        type: String,
        default: '',
      },
      selectedVariant: {
        type: Object,
        default: () => null,
      },
    },

    computed: {
      maxPrice() {
        if (!this.variants.length) {
          return 0;
        }

        return this.variants.reduce((acc, item) => {
          return item.compareAtPrice &&
            parseFloat(item.compareAtPrice) > parseFloat(0)
            ? (acc = item.compareAtPrice)
            : acc;
        }, 0);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-product--content--price {
    font-weight: 400;
    line-height: 16px;
    display: flex;
    align-items: center;

    .price {
      &-value {
        color: #343434;
        font-size: 18px;
        margin-right: 8px;

        @include sm() {
          font-size: 20px;
        }
      }

      &-discount {
        position: relative;
        font-size: 14px;
        color: #b6c0d3;

        @include sm() {
          font-size: 16px;
        }

        &:after {
          content: '';
          position: absolute;
          top: calc(50% - 1px);
          left: -2px;
          width: calc(100% + 4px);
          height: 1px;
          background: #f11e1e;
        }
      }

      &-status {
      }
    }
  }
</style>
