<template>
  <div class="sp-product--content">
    <div class="sp-product--content--head">
      <div
        class="head-status"
        :class="{
          able: metafields.available === 'not_active',
          disable: metafields.available === 'out_of_stock',
        }"
      >
        {{ metafields.available.split('_').join(' ') }}
      </div>

      <div class="head-status--buttons">
        <button @click="handleToggleToWishlist">
          <img :src="heartImg" class="product__button-icon" alt="Like" />
        </button>

        <button @click="openShareDialog">
          <img
            src="@/assets/icons/icon-sharing.svg"
            class="product__button-icon"
            alt="Sharing"
          />
        </button>
      </div>
    </div>

    <h1 class="sp-product--content--title">
      {{ product.title || 'Product Title' }}
    </h1>

    <product-price-component
      :product="product"
      :variants="variants"
      :product-available="metafields.available"
      :selected-variant="selectedVariant.selected"
      class="mb-3"
    />

    <product-content-select-variants-component
      v-if="!product.hasOnlyDefaultVariant"
      :loading="loading"
      :product="product"
      :variants="variants"
      @select:variant="handleSelectVariant"
    />

    <product-content-actions-component
      :loading="loading"
      :selected-variant="selectedVariant"
      :is-out-of-stock="isOutOfStock"
      class="mb-3"
      @set:count="handleSetCount"
      @add:cart="handleAddToCart"
      @add:cart-redirect="handleAddToCartAndRedirect"
    />

    <product-information-component
      :product="product"
      :metafields="metafields"
      class="mb-3"
    />

  </div>
</template>

<script>
  import ProductPriceComponent from './product-content/product-content-price.component';
  import ProductInformationComponent from './product-content/product-content-information.component';
  import ProductContentSelectVariantsComponent from './product-content/product-content-select-variants.component';
  import ProductContentActionsComponent from './product-content/product-content-actions.component';

  const addedHeartImg = require('/src/assets/icons/added-to-wishlist-icon.svg');
  const addHeartImg = require('/src/assets/icons/add-to-wishlist-icon.svg');

  export default {
    name: 'ProductContentComponent',
    components: {
      ProductPriceComponent,
      ProductInformationComponent,
      ProductContentSelectVariantsComponent,
      ProductContentActionsComponent,
    },
    props: {
      product: {
        type: Object,
        default: () => ({}),
        require: true,
      },
      amsProduct: {
        type: Object,
        require: true,
        default: () => ({}),
      },

      selectedVariant: {
        type: Object,
        default: () => ({}),
        require: true,
      },

      metafields: {
        type: Object,
        default: () => ({}),
      },

      store: {
        type: Object,
        default: () => ({}),
        require: true,
      },

      loading: {
        type: Boolean,
        default: false,
      },

      isInWishList: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      isOutOfStock() {
        return this.metafields?.available === 'out_of_stock';
      },

      heartImg() {
        return this.isInWishList ? addedHeartImg : addHeartImg;
      },

      variants() {
        return this.product.variants.edges
          .map((item) => ({
            ...item.node,
            splittedTitle: item.node.title.split(' / '),
          }))
          .filter((item) => {
            const object = item.metafields.edges.find(
              (object) => object.node.key === 'enabled',
            );

            return !object ? item : object.node.value !== 'false' ? item : null;
          });
      },
    },

    methods: {
      handleToggleToWishlist() {
        this.$emit('toggle:wishlist');
      },
      openShareDialog() {
        this.$bus.$emit('ToggleSharingDialog', {
          currentStoreLink: `${this.$location}${this.amsProduct.share}`,
          currentStoreName: this.amsProduct.store?.name,
          item: this.amsProduct,
        });
      },

      handleAddToCart() {
        this.$emit('add:cart');
      },

      handleAddToCartAndRedirect() {
        this.$emit('add:cart-redirect');
      },

      handleSelectVariant(variant) {
        this.$emit('select:variant', variant);
      },

      handleSetCount(count) {
        this.$emit('set:count', count);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-product--content {
    &--head {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .head {
        &-status {
          padding: 10px 15px;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #343434;
          background: #d5f4e1;
          border-radius: 50px;
          text-transform: capitalize;
          margin-bottom: 16px;

          &.able {
            background: red;
          }

          &.disable {
            background: #ccc;
          }

          &--buttons {
            display: flex;
            align-items: center;
            justify-content: center;

            button {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 38px;
              height: 38px;
              border: 1px solid #eff2fa;
              border-radius: 50%;
              outline: none;

              &:not(:first-child) {
                margin-left: 7px;
              }

              img {
                width: 18px;
                height: 18px;
              }
            }
          }
        }
      }
    }

    &--title {
      font-size: 18px;
      font-weight: 500;
      line-height: 32 / 22 * 100%;
      color: #343434;

      @include sm() {
        font-size: 22px;
      }
    }
  }
</style>
