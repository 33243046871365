<template>
  <div v-if="videos.length" class="sp-product--swiper-carousel">
    <swiper-carousel
      :items="videos"
      :config="carouselConfig"
      :hide-arrows-controls="videos.length < 2"
      hide-show-more
      class="swiper-carousel"
      @change:slide="handleChangeSlide"
    >
      <template #item="{ item, index }">
        <video-overlay-component
          :ref="`video-overlay-${index}`"
          :index="index"
          :active-index="activeSlide"
          :item="item"
          muted
          autoplay
        />
      </template>
    </swiper-carousel>
  </div>
</template>

<script>
  import SwiperCarousel from 'src/components/swiper-carousel/swiper-carousel';
  import VideoOverlayComponent from './video-overlay.component';

  import { SINGLE_ITEMS_CONFIG } from 'src/config/carousel.config';

  export default {
    name: 'ProductGalleryComponent',

    components: {
      SwiperCarousel,
      VideoOverlayComponent,
    },

    props: {
      videos: {
        type: Array,
        default: () => [],
        require: true,
      },
    },

    data() {
      return {
        carouselConfig: SINGLE_ITEMS_CONFIG,
        activeSlide: 0,
      };
    },

    mounted() {
      window.addEventListener('scroll', this.handleEventScroll);
    },

    beforeDestroy() {
      window.removeEventListener('scroll', this.handleEventScroll);
    },

    methods: {
      handleChangeSlide(index) {
        // console.log('handleChangeSlide', index);
        const previousVideo = this.$refs[`video-overlay-${this.activeSlide}`];

        const isPaused = previousVideo.paused;
        const isMuted = previousVideo.localMuted;

        previousVideo.handlePauseAndRestart();
        previousVideo.handleCancelRoll();

        const currentVideo = this.$refs[`video-overlay-${index}`];

        if (!isPaused) {
          setTimeout(() => {
            currentVideo.handlePlay();
          }, 100);
        } else {
          setTimeout(() => {
            this.$nextTick(() => {
              currentVideo.handlePauseAndRestart();
              currentVideo.$refs.controls.style = 'display: block';
              currentVideo.$forceUpdate();
            })
          }, 150);
        }

        isMuted ? currentVideo.handleMute() : currentVideo.handleUnmute();

        this.activeSlide = index;
      },

      handleEventScroll() {
        const video = this.$refs[`video-overlay-${this.activeSlide}`];

        if (window.scrollY > 300) {
          video.handleCancelRoll();
        }
      },
    },
  };
</script>

<style lang="scss">
  .sp-product--swiper-carousel {
    overflow: hidden;
    border-radius: 10px;

    .swiper-carousel {
      margin-bottom: 0px;

      .swiper-container {
        .swiper-action {
          color: black;
          border: none !important;
          width: 35px !important;
          height: 35px !important;
          transform: none !important;

          &:hover {
            background-color: #eef0f4;

            &::after {
              color: black !important;
            }
          }

          @media (max-width: 768px) {
            display: flex !important;
          }

          &.swiper-button-disabled {
            display: none !important;
            opacity: 1 !important;
            cursor: pointer;
            pointer-events: initial;
            visibility: visible !important;

            // @media screen and (max) {

            // }
          }

          &.swiper-button-prev {
            left: 12px !important;
          }

          &.swiper-button-next {
            right: 12px !important;
          }
        }
      }
    }
  }
</style>
