<template>
  <product-component
    v-if="product && amsProduct"
    :id="productId"
    :ams-product="amsProduct"
    :product="product"
  />
</template>

<script>
  import { mapActions } from 'vuex';
  import ProductComponent from '@/shared/features/product/product.component';
  import SharedProductAPI from '@/shared/api/product/product.api';
  import ProductAPI from './api';
  import device from '@/service/device-service';

  export default {
    name: 'ProductContainer',
    components: {
      ProductComponent,
    },
    data() {
      return {
        // oldProductId: null,
        product: null,
        amsProduct: null,
        productVariantsPricelist: {},
      };
    },
    computed: {
      isMobile() {
        return device.isAnyMobile() || device.isMobileSize();
      },
      productId() {
        return this.$route.params.id;
      },
    },
    //TODO Need refactoring!
    async mounted() {
      // this.setPlaybackState({})

      await this.fetchProduct();
      await this.fetchAmsProductId();
      await this.makeProductAsLastViewed();

      document.title = this.product.title

      this.$stats.send(
        'page_opened',
        {
          page_type: 'product',
          product_id: this.productId,
        },
        { item: this.amsProduct },
      );

      const container = document.getElementById('app-content');
      container.style.overflow = 'inherit';
    },

    beforeDestroy() {
      const container = document.getElementById('app-content');
      container.removeAttribute('style');
    },

    methods: {
      ...mapActions({
        setPlaybackState: 'floatPlayer/setPlaybackState',
      }),
      async fetchProduct() {
        try {
          const { productByHandle: product } = await SharedProductAPI.getProductByHandle(
            this.productId,
          );

          if (!product) {
            this.$plError.setError({
              text: 'Product not found',
              title: 'Not found',
              code: 404,
            });

            throw new Error('Product not found');
          }

          this.product = product;
        } catch (err) {
          this.$router.replace({ path: '/error'  });
        }
      },
      async fetchAmsProductId() {
        try {
          console.log('this.product.id: ', this.product.id);
          const id = this.product.id.substring('gid://shopify/Product/'.length);
          const response = await ProductAPI.getProduct(id);

          this.amsProduct = {
            ...response.head,
            ...(response.variants && { variantsPricelist: response.variants }),
          };
        } catch (err) {
          console.log(err);
        }
      },

      makeProductAsLastViewed() {
        ProductAPI.makeProductAsLastViewed(this.amsProduct.id);
      },
    },
  };
</script>
