<template>
  <div class="sp-product">
    <div class="sp-product--container">
      <div v-if="!fetchDataLoading" class="sp-product--inner">
        <product-media-component
          ref="product-media"
          :product="product"
          :videos="videos"
          class="sp-product--inner-media"
        />

        <product-content-component
          :product="product"
          :ams-product="amsProduct"
          :selected-variant="variant"
          :metafields="metafields"
          :store="store"
          :loading="loading"
          :is-in-wish-list="isInWishList"
          class="sp-product--inner-content"
          @add:cart="handleAddToCart"
          @add:cart-redirect="handleAddToCartAndRedirect"
          @toggle:wishlist="handleToggleToWishlist"
          @select:variant="handleSelectVariant"
          @set:count="handleSetCount"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { amsClient } from 'src/service/ams';
  import Auth from '@/service/authService';
  import WishlistService from '@/store/wishlist';
  import SharedCartDataService from '@/shared/services/cart/cart-data.service';
  import SharedProductAPI from '@/shared/api/product/product.api';
  import { AuthVariableMixin } from '@/plugins/mixin';
  import normalizeImg from '@/filters/normalizeImg';
  import ProductContentComponent from './components/product-content.component';
  import ProductMediaComponent from './components/product-media.component';
  import ReferalService from '@/shared/services/referal.service';
  import { updateVariantPriceFromPricelist, getVariantPriceFromPricelist } from '@/utils/product-pricelist';

  const CartService = new SharedCartDataService();

  export default {
    name: 'ProductComponent',
    components: {
      ProductContentComponent,
      ProductMediaComponent,
    },

    mixins: [AuthVariableMixin],

    props: {
      id: {
        type: String,
        require: true,
        default: null,
      },
      amsProduct: {
        type: Object,
        require: true,
        default: () => ({}),
      },
      product: {
        type: Object,
        require: true,
        default: () => ({}),
      },
      openFromVideo: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        store: {},

        fetchDataLoading: false,

        loading: false,

        variant: {
          selected: null,
          count: 0,
        },

        videos: null,

        vodLink: null,
      };
    },

    computed: {
      isWishlist() {
        return this.$store.getters['wishlist/getList'];
      },

      isInWishList() {
        return Object.keys(this.isWishlist).includes(this.amsProduct?.id);
      },

      isSeller() {
        const store = this.product.metafields.edges.find(
          ({ node }) => node.key === 'seller',
        );

        return store;
      },

      metafields() {
        return this.product.metafields.edges.reduce((acc, item) => {
          if (item.node.key === 'specifications') {
            acc = {
              ...acc,
              details: JSON.parse(item.node.value),
            };
          }

          if (item.node.key === 'availability') {
            acc = {
              ...acc,
              available: item.node.value,
            };
          }

          if (item.node.key === 'shipping_fee') {
            acc = {
              ...acc,
              shipping: item.node.value,
            };
          }

          if (item.node.key === 'returnable') {
            acc = {
              ...acc,
              returnable: item.node.value,
            };
          }

          if (item.node.key === 'condition') {
            acc = {
              ...acc,
              condition: item.node.value,
            };
          }

          if (item.node.key === 'videos') {
            acc = {
              ...acc,
              videos: JSON.parse(item.node.value)?.videos || [],
            };
          }

          return acc;
        }, {});
      },
      isUser() {
        return this.$store.getters['authenticate/isUser'];
      },

      isCartId() {
        if (!this.isUser.guest) {
          return Auth.get('user.cart');
        }

        return localStorage.getItem('cartId');
      },
    },

    async mounted() {
      await this.fetchVodEnvLink();

      this.fetchDataLoading = true;

      await Promise.all([
        this.fetchStoreInformation(),
        this.fetchVideosForProduct(),
        this.fetchShortsVideosForProduct(),
      ]).then(() => {
        this.fetchDataLoading = false;
      });

      console.log('this.videos: ', this.videos);

      if (this.product.hasOnlyDefaultVariant) {
        // TODO: remove after POC
        this.variant.selected = updateVariantPriceFromPricelist(this.product.variants.edges[0].node, this.amsProduct.variantsPricelist);

        if (this.variant.selected.inventoryItem.tracked) {
          this.variant.count = this.variant.selected.inventoryQuantity === 0 ? 0 : 1;
        } else {
          this.variant.count = 1;
        }
      }
    },

    methods: {
      normalizeImg,

      async fetchVodEnvLink() {
        try {
          const template = await amsClient.callAms(`/playtemplate/`);

          this.vodLink = template.info.vod;
        } catch (error) {
          console.log(error);
        }
      },

      async fetchStoreInformation() {
        try {
          const response = await amsClient.callAms(`/ugc/creator/${this.isSeller.node.value}/?d=web`);

          this.store = {
            slug: response.head.slug,
            id: response.head.provider_id,
            avatar: response.head.picture,
            title: response.head.title,
            cats: response.head.cats,
            vimmi_store_id: response.head.id,
            crm_store_id: response.head.store_id,
          };
        } catch (error) {
          console.log(error);
        }
      },

      async fetchVideosForProduct() {
        if (!this.metafields.videos || !this.metafields.videos.length) {
          return;
        }

        try {
          const body = {
            ids: this.metafields.videos.map((video) => video.id),
          };

          const response = await SharedProductAPI.getProductVideosByIds(body);

          this.videos = [...response.items].map((video) => {
            return {
              ...video,
              link: video.recorded ? video.media_url : this.vodLink.replaceAll('{MID}', video.mid),
            };
          });
        } catch (error) {
          console.log(error);
        }
      },

      async fetchShortsVideosForProduct() {
        try {
          const response = await amsClient.callAms(
            `/shoprzapi/vod_playlist/${this.amsProduct.id}/?by=id&d=gstweb`,
          );

          if (!response.head.vod_playlist.length) {
            return;
          }

          const vod_playlist = response.head.vod_playlist.map((video) => {
            return {
              ...video,
              link: video.recorded ? video.media_url : this.vodLink.replaceAll('{MID}', video.mid),
            };
          });

          if (this.videos) {
            this.videos = [...this.videos, ...vod_playlist];
          } else {
            this.videos = [...vod_playlist];
          }
        } catch (error) {
          console.log(error);
        }
      },

      async handleToggleToWishlist() {
        this.loading = true;

        try {
          if (this.isInWishList) {
            await WishlistService.removeFromWishlist(this.amsProduct.id);
          } else {
            this.$stats.send('add_wishlist', {}, { item: this.amsProduct });

            await WishlistService.addToWishlist(this.amsProduct.id);
          }
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      },

      async handleAddToCart() {
        this.loading = true;

        const data = {
          id: this.variant.selected.id,
          count: this.variant.count,
          removeId: this.id,
          oldId: this.amsProduct.id,
        };
        // console.log('[handleAddToCart]', this.id, this.amsProduct, this.product, this.variant);
        this.$stats.send(
          'add_to_cart',
          {
            variant_id: this.variant.id, // todo:
            quantity: this.variant.count,
          },
          { item: this.amsProduct },
        );

        // TODO: only for POC
        const customVariantPrice = getVariantPriceFromPricelist(this.variant.selected, this.amsProduct.variantsPricelist);

        try {
          const response = await CartService.addItemToCart({
            cartId: this.isCartId,
            itemId: data.id,
            quantity: data.count,
            store: JSON.stringify(this.store) || 'null',
            referal: ReferalService.instance().encode(this.id),
            ...(customVariantPrice && { customPrice: customVariantPrice }),
          });

          this.$store.commit(
            'checkout/setCart',
            response?.cartLinesAdd?.cart || null,
          );
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }

        return data;
      },

      handleAddToCartAndRedirect() {
        const productImages = [...this.product.images.edges].map(
          (item) => item.node,
        );

        const data = {
          merchandise: {
            id: this.variant.selected.id,
            image: {
              url: productImages[0].url,
            },
            price: {
              amount: this.variant.selected.price,
              currencyCode: 'USD',
            },
            product: {
              id: '',
              title: this.product.title,
            },
            quantityAvailable: this.variant.selected.inventoryQuantity,
            title: this.variant.selected.title,
          },
          attributes: [
            {
              key: '__store',
              value: JSON.stringify(this.store) || 'null',
            },
            {
              key: '__ref',
              value: ReferalService.instance().encode(this.id),
            },
          ],
          quantity: this.variant.count,
          cost: {
            subtotalAmount: {
              amount: `${+this.variant.selected.price * this.variant.count}`,
              currencyCode: 'USD',
            },
            totalAmount: {
              amount: `${+this.variant.selected.price * this.variant.count}`,
              currencyCode: 'USD',
            },
            totalShippingAmount: {
              amount: '0.00',
              currencyCode: 'USD',
            },
          },
        };

        this.$store.commit('checkout/setBuyNowProduct', data);
        this.$router.push({ name: 'checkout' });
      },

      handleSelectVariant(variant) {
        // TODO: remove after POC
        this.variant.selected = updateVariantPriceFromPricelist(variant, this.amsProduct.variantsPricelist);

        if (!this.variant.selected) {
          this.variant.count = 0;

          return;
        }

        if (variant.image) {
          this.$refs['product-media'].handleSelectPicture(variant.image);
        }

        if (this.variant.selected.inventoryItem.tracked) {
          this.variant.count = this.variant.selected.inventoryQuantity === 0 ? 0 : 1;
        } else {
          this.variant.count = 1;
        }
      },

      handleSetCount(count) {
        this.variant = {
          ...this.variant,
          count,
        };
      },
    },
  };
</script>
<style lang="scss">
  .sp-product {
    .content {
      overflow-y: initial;
      overflow-x: initial;
    }
  }
</style>
<style lang="scss" scoped>
  .sp {
    &-product {
      &--container {
        max-width: 768px;
        padding: 0px 15px;
        margin: 0 auto;

        @include lg() {
          max-width: 1100px;
        }

        @media screen and (min-width: 1460px) {
          max-width: 1450px;
        }
      }

      &--inner {
        display: grid;
        column-gap: 12px;
        row-gap: 12px;
        grid-template-columns: 1fr;
        padding-bottom: 40px;

        @include lg() {
          grid-template-columns: minmax(320px, 1fr) minmax(320px, 560px);
          column-gap: 40px;
          row-gap: 40px;
        }

        &-media {
          overflow: hidden;

          @include lg() {
            position: sticky;
            top: 60px;
            align-self: start;

            &.opened {
              z-index: 1030;
            }
          }
        }
      }
    }
  }
</style>
