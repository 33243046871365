<template>
  <div class="sp-product--actions">
    <div class="sp-product--actions--counter">
      <button :disabled="!isEnabledCountButtons" @click="decrement">-</button>

      <span>{{ selectedVariant.count }}</span>

      <button :disabled="!isEnabledCountButtons" @click="increment">+</button>
    </div>

    <button
      class="sp-product--actions--button button-primary"
      :class="{ disabled: isDisableActions, 'button-secondary': inCartVariant }"
      :disabled="isDisableActions"
      @click="handleAddToCart"
    >
      {{ inCartVariant ? 'GO TO CART' : 'ADD TO CART' }}
    </button>

    <button
      class="sp-product--actions--button button-default"
      :class="{ disabled: isDisableActions }"
      :disabled="isDisableActions"
      @click="handleAddToCartAndRedirect"
    >
      buy now
    </button>
  </div>
</template>

<script>
  export default {
    name: 'ProductContentActionsComponent',
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
      selectedVariant: {
        type: Object,
        default: () => ({}),
        require: true,
      },
      isOutOfStock: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
      isDisableActions() {
        return (
          this.isOutOfStock ||
          (!this.selectedVariant.count && !this.selectedVariant.selected) ||
          this.loading
        );
      },

      isEnabledCountButtons() {
        return (
          !this.isOutOfStock &&
          this.selectedVariant.selected &&
          (!this.selectedVariant.selected.inventoryItem.tracked ||
            this.selectedVariant.selected.inventoryQuantity)
        );
      },

      inCartVariant() {
        const products = this.$store.getters['checkout/getCartItems'];

        return products.find(
          (product) =>
            product.node.merchandise.id === this.selectedVariant?.selected?.id,
        );
      },
    },

    methods: {
      handleAddToCart() {
        if (this.inCartVariant) {
          this.$router.push({ name: 'cart' });

          return;
        }

        this.$emit('add:cart');
      },

      handleAddToCartAndRedirect() {
        this.$emit('add:cart-redirect');
      },

      increment() {
        if (!this.selectedVariant.selected) {
          return;
        }

        if (!this.selectedVariant.selected?.inventoryItem?.tracked) {
          this.$emit('set:count', this.selectedVariant.count + 1);
        } else {
          if (
            this.selectedVariant.selected?.inventoryQuantity >
            this.selectedVariant.count
          ) {
            this.$emit('set:count', this.selectedVariant.count + 1);
          }
        }
      },

      decrement() {
        if (this.selectedVariant.count <= 0) {
          this.$emit('set:count', this.selectedVariant.count);
        } else {
          this.$emit('set:count', this.selectedVariant.count - 1);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-product--actions {
    display: grid;
    column-gap: 5px;
    grid-template-columns: minmax(70px, 120px) minmax(154px, 1fr) minmax(
        95px,
        260px
      );

    @include lg() {
      column-gap: 8px;
      grid-template-columns: 100px 1fr minmax(100px, 160px);
    }

    &--counter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 5px;
      padding: 2px;
      border: 1px solid #edf1f4;
      font-size: 15px;

      @include lg() {
        padding: 5px;
        font-size: 16px;
      }

      button {
        height: 100%;
        font-weight: 500;
        border-radius: 3px;
        width: 18px;
        font-size: 14px;
        outline: none;

        &:hover {
          background-color: #edf1f4;
        }

        @include lg() {
          font-size: 20px;
          width: 21px;
        }
      }
    }

    &--button {
      height: 50px;
      border-radius: 5px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      line-height: 24 / 15 * 100%;
      outline: none;

      @include lg() {
        font-size: 15px;
      }

      &.button-primary {
        background-color: #21a66e;
        color: #ffffff;
      }

      &.button-default {
        border: 1px solid #edf1f4;
        color: #343434;
      }

      &.button-secondary {
        background-color: #ffc700;
        color: #343434;
      }

      &.disabled {
        background-color: #edf1f4;
        color: #ffffff;
        pointer-events: none;
      }
    }
  }
</style>
