<template>
  <div>
    <div class="sp-product--content--information">
      <h3 class="sp-product--content--subtitle">Product Description</h3>

      <div class="inner-content" v-html="product.descriptionHtml"/>
    </div>

    <div class="sp-product--content--policy">
      <div v-if="metafields.shipping" class="policy-item">
        <h3>Shipping Fee</h3>

        <span>
          {{
            metafields.shipping === '0.0' ? 'Free' : '$' + metafields.shipping
          }}
        </span>
      </div>

      <div v-if="metafields.returnable" class="policy-item">
        <h3>Returnable Policy</h3>

        <span>
          {{
            metafields.returnable === 'false'
              ? 'Not returnable'
              : 'Within 14 days'
          }}
        </span>
      </div>

      <div v-if="metafields.condition" class="policy-item">
        <h3>Product Conditions</h3>

        <span>
          {{ metafields.condition }}
        </span>
      </div>
    </div>

    <div
      v-if="metafields.details && metafields.details.length"
      class="sp-product--content--information"
    >
      <h3 class="sp-product--content--subtitle">Product details</h3>

      <ul class="information--list inner-content">
        <li
          v-for="(item, index) in metafields.details"
          :key="index"
          class="information--item"
        >
          <h4 class="information--item-title">{{ item.name }}</h4>
          <p
            class="information--item-value"
            v-html="parseLink(item.value)"
          />
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { parseLink } from '@/shared/helpers/custom-link-parse.helper';

  export default {
    props: {
      product: {
        type: Object,
        default: () => ({}),
      },

      metafields: {
        type: Object,
        default: () => ({}),
      },
    },

    methods: {
      parseLink,
    }
  };
</script>

<style lang="scss">
  .inner-content {
    & > * {
      font-size: 15px;
      line-height: 18px;
    }
    a {
      color: revert;
    }
  }
</style>

<style lang="scss" scoped>
  .sp-product--content {
    &--subtitle {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      color: #343434;
      margin-bottom: 20px;
    }

    &--policy {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: 20px;
      margin-bottom: 30px;

      .policy-item {
        h3 {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 8px;
        }

        span {
          font-weight: 400;
          font-size: 15px;
          line-height: 18px;
        }
      }
    }

    &--information {
      color: #343434;
      margin-bottom: 30px;

      .information {
        &--list {
          list-style: none;
          padding: 0;
        }

        &--item {
          display: flex;
          align-items: flex-start;
          //justify-content: space-between;
          font-weight: 400;
          margin-bottom: 18px;

          @include xs() {
            flex-direction: column;
          }

          &-title {
            font-size: 15px;
            line-height: 18px;
            margin-bottom: 0;
            font-weight: 400;
            word-break: break-word;
            overflow-wrap: break-word;
            width: calc(50% - 10px);
            color: #a0a6b9;
          }

          &-value {
            text-align: left;
            margin-bottom: 0;
            width: calc(50% - 5px);
            word-break: break-word;
            overflow-wrap: break-word;
            @include xs() {
              text-align: left;
            }
          }
        }
      }
    }
  }
</style>