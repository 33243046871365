import { render, staticRenderFns } from "./video-overlay.component.vue?vue&type=template&id=5c0d6417&scoped=true"
import script from "./video-overlay.component.vue?vue&type=script&lang=js"
export * from "./video-overlay.component.vue?vue&type=script&lang=js"
import style0 from "./video-overlay.component.vue?vue&type=style&index=0&id=5c0d6417&prod&lang=scss"
import style1 from "./video-overlay.component.vue?vue&type=style&index=1&id=5c0d6417&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c0d6417",
  null
  
)

export default component.exports