<template>
  <div class="video-overlay" :class="{ rolls: isRolls }">
    <video
      ref="video"
      :muted="localMuted"
      :autoplay="autoplay"
      :controls="false"
      :poster="item.poster"
      playsinline
      @timeupdate="updateProgress"
    />
    <div
      ref="controls"
      class="video-overlay--controls"
      @click="handleTogglePlay"
    >
      <span class="controls-muted" @click.stop="handleMuted">
        <svg
          v-if="localMuted"
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.55208 7.09805L9.44444 3.59648V14.4035L5.55208 10.902C5.39931 10.7648 5.20139 10.6875 5 10.6875H1.94444C1.79167 10.6875 1.66667 10.5609 1.66667 10.4062V7.59375C1.66667 7.43906 1.79167 7.3125 1.94444 7.3125H5C5.20486 7.3125 5.40278 7.23516 5.55208 7.09805ZM10.0764 1.125C9.82292 1.125 9.57986 1.21992 9.38889 1.38867L4.68403 5.625H1.94444C0.871528 5.625 0 6.50742 0 7.59375V10.4062C0 11.4926 0.871528 12.375 1.94444 12.375H4.68403L9.38889 16.6113C9.57986 16.7801 9.82292 16.875 10.0764 16.875C10.6493 16.875 11.1111 16.4074 11.1111 15.8273V2.17266C11.1111 1.59258 10.6493 1.125 10.0764 1.125ZM14.7569 5.87109C14.4306 5.54063 13.9028 5.54063 13.5799 5.87109C13.2569 6.20156 13.2535 6.73594 13.5799 7.06289L15.4896 8.99648L13.5799 10.9301C13.2535 11.2605 13.2535 11.7949 13.5799 12.1219C13.9063 12.4488 14.434 12.4523 14.7569 12.1219L16.6667 10.1883L18.5764 12.1219C18.9028 12.4523 19.4306 12.4523 19.7535 12.1219C20.0764 11.7914 20.0799 11.257 19.7535 10.9301L17.8438 8.99648L19.7535 7.06289C20.0799 6.73242 20.0799 6.19805 19.7535 5.87109C19.4271 5.54414 18.8993 5.54063 18.5764 5.87109L16.6667 7.80469L14.7569 5.87109Z"
            fill="white"
          />
        </svg>

        <svg
          v-else
          width="20"
          height="16"
          viewBox="0 0 20 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_928_164)">
            <path
              d="M16.675 1.01568C16.3531 0.753183 15.8812 0.803183 15.6187 1.12506C15.3562 1.44693 15.4062 1.91881 15.7281 2.18131C17.4219 3.55631 18.5 5.65006 18.5 8.00006C18.5 10.3501 17.4219 12.4438 15.7281 13.8219C15.4062 14.0844 15.3594 14.5563 15.6187 14.8782C15.8781 15.2001 16.3531 15.2469 16.675 14.9876C18.7031 13.3376 20 10.8188 20 8.00006C20 5.18131 18.7031 2.66256 16.675 1.01568ZM14.7844 3.34381C14.4625 3.08131 13.9906 3.13131 13.7281 3.45318C13.4656 3.77506 13.5156 4.24693 13.8375 4.50943C14.8531 5.33443 15.5 6.59068 15.5 8.00006C15.5 9.40943 14.8531 10.6657 13.8375 11.4938C13.5156 11.7563 13.4688 12.2282 13.7281 12.5501C13.9875 12.8719 14.4625 12.9188 14.7844 12.6594C16.1344 11.5594 17 9.88131 17 8.00318C17 6.12506 16.1344 4.44693 14.7844 3.34693V3.34381ZM12.8938 5.67193C12.5719 5.40943 12.1 5.45943 11.8375 5.78131C11.575 6.10318 11.625 6.57506 11.9469 6.83756C12.2844 7.11256 12.5 7.53131 12.5 8.00006C12.5 8.46881 12.2844 8.88756 11.9469 9.16568C11.625 9.42818 11.5781 9.90006 11.8375 10.2219C12.0969 10.5438 12.5719 10.5907 12.8938 10.3313C13.5656 9.77818 14 8.94068 14 8.00006C14 7.05943 13.5656 6.22193 12.8938 5.67193ZM4.99687 6.30943L8.5 3.19693V12.8032L4.99687 9.69068C4.85938 9.56881 4.68125 9.50006 4.5 9.50006H1.75C1.6125 9.50006 1.5 9.38756 1.5 9.25006V6.75006C1.5 6.61256 1.6125 6.50006 1.75 6.50006H4.5C4.68437 6.50006 4.8625 6.43131 4.99687 6.30943ZM9.06875 1.00006C8.84063 1.00006 8.62187 1.08443 8.45 1.23443L4.21562 5.00006H1.75C0.784375 5.00006 0 5.78443 0 6.75006V9.25006C0 10.2157 0.784375 11.0001 1.75 11.0001H4.21562L8.45 14.7657C8.62187 14.9157 8.84063 15.0001 9.06875 15.0001C9.58438 15.0001 10 14.5844 10 14.0688V1.93131C10 1.41568 9.58438 1.00006 9.06875 1.00006Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_928_164">
              <rect width="20" height="16" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </span>

      <button v-if="paused" class="controls-play" @click.stop="handleTogglePlay">
        <svg
          width="30"
          height="40"
          viewBox="0 0 30 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.5">
            <path
              d="M5.70312 3.04699C4.54687 2.33605 3.09375 2.31261 1.91406 2.97667C0.734375 3.64074 0 4.89074 0 6.25011V33.7501C0 35.1095 0.734375 36.3595 1.91406 37.0236C3.09375 37.6876 4.54687 37.6564 5.70312 36.9532L28.2031 23.2032C29.3203 22.5236 30 21.3126 30 20.0001C30 18.6876 29.3203 17.4845 28.2031 16.797L5.70312 3.04699Z"
              fill="white"
            />
          </g>
        </svg>
      </button>

      <svg
        v-if="localMuted"
        class="controls-muted-mobile"
        width="52"
        height="45"
        viewBox="0 0 52 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g opacity="0.4">
          <path
            d="M14.4354 17.7451L24.5556 8.99121V36.0088L14.4354 27.2549C14.0382 26.9121 13.5236 26.7188 13 26.7188H5.05556C4.65833 26.7188 4.33333 26.4023 4.33333 26.0156V18.9844C4.33333 18.5977 4.65833 18.2812 5.05556 18.2812H13C13.5326 18.2812 14.0472 18.0879 14.4354 17.7451ZM26.1986 2.8125C25.5396 2.8125 24.9076 3.0498 24.4111 3.47168L12.1785 14.0625H5.05556C2.26597 14.0625 0 16.2686 0 18.9844V26.0156C0 28.7314 2.26597 30.9375 5.05556 30.9375H12.1785L24.4111 41.5283C24.9076 41.9502 25.5396 42.1875 26.1986 42.1875C27.6882 42.1875 28.8889 41.0186 28.8889 39.5684V5.43164C28.8889 3.98145 27.6882 2.8125 26.1986 2.8125ZM38.3681 14.6777C37.5194 13.8516 36.1472 13.8516 35.3076 14.6777C34.4681 15.5039 34.459 16.8398 35.3076 17.6572L40.2729 22.4912L35.3076 27.3252C34.459 28.1514 34.459 29.4873 35.3076 30.3047C36.1562 31.1221 37.5285 31.1309 38.3681 30.3047L43.3333 25.4707L48.2986 30.3047C49.1472 31.1309 50.5194 31.1309 51.359 30.3047C52.1986 29.4785 52.2076 28.1426 51.359 27.3252L46.3938 22.4912L51.359 17.6572C52.2076 16.8311 52.2076 15.4951 51.359 14.6777C50.5104 13.8604 49.1382 13.8516 48.2986 14.6777L43.3333 19.5117L38.3681 14.6777Z"
            fill="white"
          />
        </g>
      </svg>

      <div
        ref="progressBar"
        class="controls-progress"
        :class="{ 'is-vertical': item.media_is_vertical }"
      >
        <span
          class="controls-progress--current"
          :style="{ width: `${progress}%` }"
        />

        <span
          class="controls-progress--buffered"
          :style="{ width: `${buffered}%` }"
        />
      </div>

      <div
        v-if="item.media_is_vertical"
        class="controls-arrows"
        :class="{ active: isRolls }"
        @click.stop="handleRolls"
      >
        <svg
          width="16"
          height="10"
          viewBox="0 0 16 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.19312 8.7909C7.6394 9.23637 8.36416 9.23637 8.81044 8.7909L15.6653 1.94849C16.1116 1.50302 16.1116 0.779575 15.6653 0.334105C15.219 -0.111365 14.4943 -0.111365 14.048 0.334105L8 6.37111L1.95202 0.337666C1.50575 -0.107804 0.780988 -0.107804 0.334709 0.337666C-0.11157 0.783135 -0.11157 1.50658 0.334709 1.95205L7.18956 8.79446L7.19312 8.7909Z"
            fill="white"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
  import HlsVideoService from '../../services/hls-player.service';

  const HlsService = new HlsVideoService();

  export default {
    name: 'VideoOverlayComponent',

    props: {
      index: {
        type: Number,
        default: 0,
      },

      activeIndex: {
        type: Number,
        default: 0,
      },

      item: {
        type: Object,
        default: () => ({}),
      },

      muted: {
        type: Boolean,
        default: false,
      },

      autoplay: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        localMuted: this.muted,
        paused: false,
        progress: 0,
        buffered: 0,
        isDragging: false,
        isRolls: false,
        initialize: false,
      };
    },

    computed: {
      isMobile() {
        return window.innerWidth <= 990
      },
      videoSrc() {
        return this.item.src;
      },

      canInitHLSVideo() {
        return (this.item.mid || this.item.link.indexOf('.m3u8') !==-1) && this.index === this.activeIndex;
      },
    },

    watch: {
      activeIndex() {
        if (!this.initialize && this.canInitHLSVideo) {
          this.initializePlayer();

          this.initialize = true;
        }
      },
    },

    mounted() {
      if (this.canInitHLSVideo) {
        this.initializePlayer();

        this.initialize = true;
      }
    },

    beforeDestroy() {
      this.stopPlayer();
    },

    methods: {
      init() {
        if (this.canInitHLSVideo) {
          this.initializePlayer();

          this.initialize = true;
        }
      },
      initializePlayer() {
        HlsService.bindVideoElement(this.$refs.video, this.item.link);

        HlsService.play().then((canPlay) => {
          if (!canPlay) {
            console.error('Video cannot be played.');
          }
        });
      },

      stopPlayer() {
        HlsService.stop();
      },

      handleTogglePlay() {
        if (this.isMobile && this.localMuted) {
          this.handleMuted()
        } else if (this.paused) {
          this.handlePlay();
          // WEB2-1950
          if (this.isMobile && this.item.media_is_vertical) {
            this.isRolls = true;
          }
        } else {
          this.handlePause();
        }
      },

      handleMute() {
        this.localMuted = true;
      },

      handleUnmute() {
        this.localMuted = false;
      },

      handleMuted() {
        this.localMuted = !this.localMuted;

        if (
          !this.localMuted &&
          this.item.media_is_vertical &&
          window.innerWidth <= 990
        ) {
          this.isRolls = true;
        }
      },

      handlePlay() {
        this.$refs.video.play();
        this.paused = false;
      },

      handlePause() {
        this.$refs.video.pause();

        this.paused = true;
      },

      handlePauseAndRestart() {
        this.handlePause();

        this.$refs.video.currentTime = 0;
      },

      handleRolls() {
        this.isRolls = !this.isRolls;
      },

      handleCancelRoll() {
        this.isRolls = false;
      },

      updateProgress() {
        const { currentTime, duration, buffered } = this.$refs.video;

        this.progress = (currentTime / duration) * 100;

        if (duration > 0 && buffered.length > 0) {
          const loadedTime = buffered.end(buffered.length - 1);
          this.buffered = (loadedTime / duration) * 100;
        }

        if (duration <= currentTime) {
          this.$refs.video.currentTime = 0;
          this.handlePlay();
        }
      },

      seekVideo(event) {
        const { clientX, target } = event;
        const { offsetWidth, offsetLeft } = target;
        const clickX = clientX - offsetLeft;
        const percentage = (clickX / offsetWidth) * 100;
        const { duration } = this.$refs.video;
        const seekTime = (duration * percentage) / 100;

        this.$refs.video.currentTime = seekTime;

        this.progress = (seekTime / duration) * 100;
      },

      startDrag() {
        this.isDragging = true;
      },

      drag(event) {
        if (!this.isDragging) {
          return;
        }

        const { clientX, target } = event;
        const { offsetWidth, offsetLeft } = target;
        const clickX = clientX - offsetLeft;
        const percentage = (clickX / offsetWidth) * 100;

        this.progress = Math.min(Math.max(percentage, 0), 100);
      },

      endDrag() {
        if (!this.isDragging) {
          return;
        }

        this.isDragging = false;

        const { duration } = this.$refs.video;
        const seekTime = (duration * this.progress) / 100;

        this.$refs.video.currentTime = seekTime;
      },
    },
  };
</script>

<style lang="scss">
  .swiper-slide {
    -webkit-transform: translate3d(0, 0, 0) !important;
  }

  .sp-product--media.sp-product--inner-media {
    .carousel.swiper-carousel {
      min-height: initial;
    }
  }
</style>

<style lang="scss" scoped>
  .video-overlay {
    position: relative;
    overflow: hidden;
    background-color: #000000;
    border-radius: 10px;
    padding-bottom: 56.25%;
    transition: padding-bottom 0.2s ease;

    &.rolls {
      padding-bottom: 178%;
    }

    video {
      pointer-events: none;
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
    }

    &--controls {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      z-index: 3;
      transform: translateZ(3px);

      .controls {
        &-muted {
          width: 36px;
          height: 36px;
          background-color: rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 12px;
          right: 12px;

          &-mobile {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            pointer-events: none;

            @media screen and (min-width: 991px) {
              display: none;
            }
          }
        }

        &-play {
          cursor: pointer;
          outline: none;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        &-progress {
          position: absolute;
          bottom: 12px;
          left: 12px;
          width: calc(100% - 24px);
          height: 5px;
          overflow: hidden;
          background-color: rgba(255, 255, 255, 0.4);
          border-radius: 10px;
          z-index: 20;

          &.is-vertical {
            bottom: 32px;

            @media (min-width: 767px) {
              bottom: 12px !important;
            }
          }

          &--current {
            position: absolute;
            height: 100%;
            width: 0;
            background-color: #ffffff;
            z-index: 2;
            border-radius: 10px;
            pointer-events: none;
            transition: 0.1s ease;
          }

          &--buffered {
            position: absolute;
            height: 100%;
            width: 0;
            background-color: rgba(255, 255, 255, 0.3);
            border-radius: 10px;
            pointer-events: none;
          }
        }

        &-arrows {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 26px;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          @media (min-width: 767px) {
            display: none !important;
          }

          svg {
            transition: transform 0.2s ease;
          }

          &.active {
            svg {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
</style>
