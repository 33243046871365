import Hls from 'hls.js';

export default class HlsVideoPlayer {
  #hlsInstance = null;

  #videoElement = null;

  #videoSource = null;

  #isNativeSupport = false;

  #endVideoCallback = () => {};

  constructor() {
    this.#isNativeSupport = this.checkNativeSupport();
  }

  checkNativeSupport() {
    const videoElement = document.createElement('video');
    return videoElement.canPlayType('application/vnd.apple.mpegurl') !== '';
  }

  bindVideoElement(videoElement, videoSource) {
    this.#videoElement = videoElement;
    this.#videoSource = videoSource;
  }

  async play() {
    if (!this.#videoElement || !this.#videoSource) {
      return false;
    }

    // if (this.#isNativeSupport) {
    //   this.#videoElement.src = this.#videoSource;
    //   await this.#videoElement.play();
    // } else {
    this.#hlsInstance = new Hls();

    this.#hlsInstance.config.maxMaxBufferLength = 5;

    this.#hlsInstance.on(Hls.Events.MANIFEST_PARSED, () => {
      this.#hlsInstance.currentLevel = 1;
      this.#hlsInstance.autoLevelEnabled = true;
      this.#hlsInstance.nextLevel = 1;

      this.#videoElement.play();
    });

    this.#hlsInstance.loadSource(this.#videoSource);
    this.#hlsInstance.attachMedia(this.#videoElement);
    // }

    return true;
  }

  stop() {
    if (this.#isNativeSupport) {
      this.#videoElement.pause();
      this.#videoElement.src = '';
    } else if (this.#hlsInstance) {
      this.#hlsInstance.stopLoad();
      this.#hlsInstance.detachMedia();
    }

    this.#videoElement = null;
    this.#videoSource = null;
    this.#hlsInstance = null;
  }

  onVideoEnded(callback) {
    this.#endVideoCallback = callback;
    if (this.#videoElement) {
      this.#videoElement.onended = () => {
        this.#endVideoCallback();
      };
    }
  }
}
