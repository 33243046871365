<template>
  <div>
    <div
      v-for="(option, index) in availableOptions"
      :key="index"
      class="sp-product--content--options"
    >
      <template v-if="option.values.length">
        <h4 class="option-title">
          {{ option.name }}
        </h4>

        <ul class="option-list">
          <li
            v-for="(item, i) in option.values"
            :key="i"
            class="option-item"
            :class="{
              selected: selected[option.name] === item.name,
              disabled: item.disabled,
            }"
            @click="handleSelectVariant(item.name, option.name)"
          >
            {{ item.name }}
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      product: {
        type: Object,
        default: () => ({}),
      },

      variants: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        selected: {},
        availableOptions: [],
      };
    },

    computed: {},

    mounted() {
      this.onMountAvailableOptions();
      this.onMountSelectedOptions();
      this.onSelectFirstAvailableVariant();
    },

    methods: {
      onMountAvailableOptions() {
        this.product.options.forEach((item) => {
          const options = [];

          item.values.map((value) => {
            this.variants.forEach((variant) => {
              if (
                variant.title.includes(value) &&
                (!variant.inventoryItem.tracked || variant.inventoryQuantity) &&
                !options.find((item) => item.name === value)
              ) {
                options.push({ name: value, disabled: false });
              }
            });
          });

          this.availableOptions.push({
            name: item.name,
            values: options,
          });
        });
      },

      onMountSelectedOptions() {
        if (this.availableOptions.length) {
          this.availableOptions.forEach((item) => {
            this.selected[item.name] = '';
          });
        }
      },

      onSelectFirstAvailableVariant() {
        const availableVariant = this.variants.find((variant) => {
          const enabledMetafield = variant.metafields.edges.find(
            (metafield) => metafield.node.key === 'enabled',
          );

          if (enabledMetafield.node.value === 'false') {
            return;
          }

          return (
            !variant.inventoryItem.tracked || variant.inventoryQuantity > 0
          );
        });

        availableVariant.splittedTitle.forEach((option, i) => {
          this.handleSelectVariant(option, this.product.options[i].name);
        });
      },

      handleSelectVariant(value, option) {
        this.selected = {
          ...this.selected,
          [option]: value,
        };

        this.recheckAvailableOptions();

        const variantTitle = Object.values(this.selected)
          .filter(Boolean)
          .join(' / ');

        if (!variantTitle) {
          this.$emit('select:variant', null);

          return;
        }

        const selectedVariant = this.variants.find(
          (variant) => variant.title === variantTitle,
        );

        this.$emit('select:variant', selectedVariant);
      },

      recheckAvailableOptions() {
        const selectedOptionValue = [];
        const notSelectedOption = [];

        for (let item in this.selected) {
          if (this.selected[item]) {
            selectedOptionValue.push(this.selected[item]);
          } else {
            notSelectedOption.push(item);
          }
        }

        let allVariantsWithItem = [...this.variants];

        selectedOptionValue.forEach((optionValue) => {
          allVariantsWithItem = allVariantsWithItem.filter((variant) =>
            variant.title.includes(optionValue),
          );
        });

        notSelectedOption.forEach((option) => {
          this.availableOptions
            .find((o) => o.name === option)
            .values.map((value) => {
              let disabled = true;

              allVariantsWithItem.forEach((item) => {
                if (item.splittedTitle.includes(value.name)) {
                  if (!item.inventoryItem.tracked || item.inventoryQuantity) {
                    disabled = false;
                    return;
                  }
                }
              });

              return (value.disabled = disabled);
            });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-product--content--options {
    margin-bottom: 24px;

    .option {
      &-title {
        font-size: 15px;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 7px;
      }

      &-list {
        padding: 0;
        margin-bottom: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
      }

      &-item {
        padding: 13px;
        border: 1px solid #e2e6ee;
        border-radius: 5px;
        margin-right: 4px;
        margin-bottom: 4px;
        font-size: 15px;
        font-weight: 400;
        line-height: 16 / 15 * 100%;
        cursor: pointer;
        transition: border-color 0.2s ease;

        &:hover {
          border-color: #343434;
        }

        &.selected {
          border-color: #21a66e;
        }

        &.disabled {
          pointer-events: none;
          color: #b6c0d3;
        }

        @include md() {
          margin-right: 6px;
          margin-bottom: 6px;
          padding: 15px;
        }
      }
    }
  }
</style>
