<template>
  <div class="sp-product--media">
    <video-gallery-component
      v-if="showVideos"
      :videos="videos"
      class="sp-product--media--video-gallery"
    />

    <picture-gallery-component
      v-if="pictures.length"
      :pictures="pictures"
      :selected-picture="selectedPicture"
      :type="videos && videos.length ? 'secondary' : 'primary'"
      class="sp-product--media--picture-gallery"
      @set:picture="handleSelectPicture"
    />
  </div>
</template>

<script>
  import PictureGalleryComponent from './product-media/picture-gallery.component';
  import VideoGalleryComponent from './product-media/video-gallery.component';

  export default {
    components: {
      VideoGalleryComponent,
      PictureGalleryComponent,
    },

    props: {
      product: {
        type: Object,
        default: () => ({}),
        require: true,
      },

      videos: {
        type: Array,
        default: () => [],
      },
    },

    data() {
      return {
        selectedPicture: null,
        showVideos: false,
      };
    },

    computed: {
      pictures() {
        return [...this.product.images.edges].map((item) => item.node);
      },
    },

    mounted() {
      this.selectedPicture = this.pictures[0];
    },

    watch: {
      videos: {
        handler(videos) {
          setTimeout(() => {
            this.showVideos = videos.length > 0;
          }, 1 * 100)
        },
        immediate: true,
      },
    },

    methods: {
      handleSelectPicture(picture) {
        this.selectedPicture = picture;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-product--media {
    &--video-gallery {
      margin-bottom: 12px;
    }
  }
</style>
