<template>
  <div class="sp-product-gallery--wrapper" :class="[type]">
    <template>
      <div
        v-if="selectedPicture"
        class="sp-product-gallery--selected"
        @click="handleShowPicture(selectedPicture)"
      >
        <span>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.0006 6.49905C13.0006 7.93321 12.535 9.25802 11.7506 10.3329L15.707 14.2917C16.0977 14.6822 16.0977 15.3165 15.707 15.7071C15.3164 16.0976 14.682 16.0976 14.2913 15.7071L10.3349 11.7483C9.25983 12.5357 7.93476 12.9981 6.50032 12.9981C2.90952 12.9981 0 10.0891 0 6.49905C0 2.90895 2.90952 0 6.50032 0C10.0911 0 13.0006 2.90895 13.0006 6.49905ZM6.50032 10.9984C7.09129 10.9984 7.67649 10.882 8.22248 10.6559C8.76847 10.4298 9.26457 10.0984 9.68245 9.68056C10.1003 9.26276 10.4318 8.76676 10.658 8.22087C10.8841 7.67499 11.0005 7.08991 11.0005 6.49905C11.0005 5.90819 10.8841 5.32311 10.658 4.77722C10.4318 4.23134 10.1003 3.73534 9.68245 3.31753C9.26457 2.89973 8.76847 2.56831 8.22248 2.3422C7.67649 2.11609 7.09129 1.99971 6.50032 1.99971C5.90934 1.99971 5.32415 2.11609 4.77816 2.3422C4.23217 2.56831 3.73607 2.89973 3.31818 3.31753C2.9003 3.73534 2.56881 4.23134 2.34266 4.77722C2.1165 5.32311 2.0001 5.90819 2.0001 6.49905C2.0001 7.08991 2.1165 7.67499 2.34266 8.22087C2.56881 8.76676 2.9003 9.26276 3.31818 9.68056C3.73607 10.0984 4.23217 10.4298 4.77816 10.6559C5.32415 10.882 5.90934 10.9984 6.50032 10.9984Z"
              fill="white"
            />
          </svg>
        </span>
        <img
          :src="selectedPicture.url"
          :alt="selectedPicture.altText || 'Product image'"
        />
      </div>

      <ul class="sp-product-gallery--list">
        <li
          v-for="picture of pictures"
          :key="picture.url + picture.altText"
          class="sp-product-gallery--item"
          :class="{
            selected: selectedPicture && picture.id === selectedPicture.id,
          }"
          @click="handleSelectPicture(picture)"
        >
          <img :src="picture.url" :alt="picture.altText || 'Product image'" />
        </li>
      </ul>
    </template>

    <swiper-carousel
      :items="pictures"
      :config="PRODUCTS_CAROUSEL_ITEMS_CONFIG"
      hide-show-more
      hide-arrows-controls
      class="swiper-carousel"
    >
      <template #item="{ item }">
        <div class="sp-product-gallery--item" @click="handleShowPicture(item)">
          <img :src="item.url" :alt="item.altText || 'Product image'" />
        </div>
      </template>
    </swiper-carousel>

    <image-preview-component
      v-if="zoomPicture"
      :src="zoomPicture.url"
      :alt="zoomPicture.altText"
      @close="handleHidePicture"
    />
  </div>
</template>

<script>
  import SwiperCarousel from 'src/components/swiper-carousel/swiper-carousel';
  import { PRODUCTS_CAROUSEL_ITEMS_CONFIG } from '@/config/carousel.config';
  import ImagePreviewComponent from '@/shared/components/image-preview.component.vue';

  export default {
    name: 'PictureGalleryComponent',

    components: {
      ImagePreviewComponent,
      SwiperCarousel,
    },

    props: {
      pictures: {
        type: Array,
        default: () => [],
        require: true,
      },

      selectedPicture: {
        type: Object,
        default: () => ({}),
        require: true,
      },

      type: {
        type: String,
        default: 'primary',
      },
    },

    data() {
      return {
        PRODUCTS_CAROUSEL_ITEMS_CONFIG,
        zoomPicture: null,
      };
    },

    methods: {
      handleSelectPicture(picture) {
        this.$emit('set:picture', picture);
      },

      handleShowPicture(picture) {
        this.zoomPicture = picture;

        const parent = document.querySelector(
          '.sp-product--media.sp-product--inner-media',
        );

        document.body.parentElement.style.overflow = 'hidden';

        parent.classList.add('opened');
      },

      handleHidePicture() {
        this.zoomPicture = null;

        const parent = document.querySelector(
          '.sp-product--media.sp-product--inner-media',
        );

        document.body.parentElement.removeAttribute('style');

        parent.classList.remove('opened');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .sp-product-gallery {
    &--wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      @include lg() {
        flex-direction: row;
      }

      .swiper-carousel {
        display: block;
        margin-bottom: 0;

        @include lg() {
          display: none;
        }
      }

      &.primary {
        flex-direction: column;
        max-width: 665px;
        margin: auto;

        .sp-product-gallery {
          &--selected {
            width: 100%;
            margin-bottom: 22px;
          }

          &--list {
            width: 100%;
            row-gap: 20px;
            column-gap: 7px;
          }
        }
      }

      &.secondary {
        .sp-product-gallery {
          &--selected {
            width: calc(50% - 6px);
            align-self: flex-start;
          }

          &--list {
            width: calc(50% - 6px);
            row-gap: 4px;
            column-gap: 4px;
          }
        }
      }
    }

    &--selected {
      border: 1px solid #e2e6ee;
      border-radius: 10px;
      overflow: hidden;
      display: none;
      cursor: pointer;
      position: relative;

      @include lg() {
        display: block;
      }

      img {
        width: 100%;
        height: auto;
      }

      span {
        position: absolute;
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        top: 8px;
        right: 8px;
      }
    }

    &--list {
      list-style: none;
      padding: 0;
      margin: 0;
      display: none;
      grid-template-columns: repeat(auto-fill, 65px);
      align-self: flex-start;
      margin-top: auto;

      @include lg() {
        display: grid;
      }
    }

    &--item {
      overflow: hidden;
      border-radius: 5px;
      border: 1px solid #e2e6ee;
      cursor: pointer;

      @include lg() {
        width: 65px;
        height: 65px;
      }

      &.selected {
        border: 1px solid #343434;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
</style>
